import { Button, Divider } from '@mui/material';
import React from 'react';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from 'react-helmet';

const theme = createTheme({
    palette: {
        primary: {
            main: "#274690", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});


function NewJerseyMainPage({ setStep, language }) {
    return (
        <div className="mt-8 container mx-auto">
            <Helmet>
                <title data-react-helmet="true"></title>
                <meta data-react-helmet="true" name="description" content="" />
                <meta data-react-helmet="true" property="og:title" content="" />
                <meta data-react-helmet="true" property="og:type" content="" />
                <meta data-react-helmet="true" property="og:url" content="" />
                <meta data-react-helmet="true" property="og:image" content="" />
                <meta data-react-helmet="true" property="og:description" content="" />
            </Helmet>
            <div className='mx-10'>

                <h1 className="text-3xl font-bold">New Jersey Driver's License Practice Test</h1>

                <div className='my-5'><Divider /></div>
                <div className=''>
                    <div className=''>

                        <div className='mt-5'>Here, you can study all the questions or take a full practice test to prepare for the actual general knowledge exam at an MVC testing center. The official test consists of 50 questions, and you'll need to score at least 80%, which means correctly answering 40 out of the 50 questions. Good luck!</div>

                    </div>
                </div>
                <div className='pt-8'>
                    <div className='lg:flex lg:gap-5'>
                        <div className='lg:w-6/12 mt-5'>
                            <div className='mx-auto'>
                                <ThemeProvider theme={theme}>
                                    <Button
                                        endIcon={<DriveFileRenameOutlineTwoToneIcon />}
                                        fullWidth
                                        variant='contained'
                                        size='large'
                                        color='primary'
                                        onClick={() => setStep('test')}
                                    >
                                        Start Test
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className='lg:w-6/12 mt-5'>
                            <div className='mx-auto'>
                                <ThemeProvider theme={theme}>
                                    <Button
                                        endIcon={<MenuBookTwoToneIcon />}
                                        fullWidth
                                        variant='contained'
                                        size='large'
                                        color='primary'
                                        onClick={() => setStep('all-questions')}
                                    >
                                        Study Questions
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </div >
    );
}

export default NewJerseyMainPage;
