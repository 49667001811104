import React, { useState, useEffect } from 'react';
import { Divider } from '@mui/material';

const All = ({ state, language }) => {
    const [questions, setQuestions] = useState([]);

    const optionLabels = ['A', 'B', 'C', 'D'];

    const stateNames = {
        Alabama: 'Alabama',
        NewJersey: 'New Jersey',
        NewYork: 'New York',
        // Add other states as needed
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`/States/${state}/${language}.json`);
                const data = await response.json();
                setQuestions(data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [state, language]);

    return (
        <div className="container px-5 pt-5">
            <h2 className="text-2xl font-bold mb-5">{`${stateNames[state]} Complete Knowledge Test Questions and Answers`}</h2>
            {questions.map((q, index) => (
                <div key={index} className="mb-3 mx-auto p-4 border rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-2" title={`${q.question}`}>{q.id}. {q.question}</h3>
                    <div className='lg:flex lg:gap-5'>
                        <div className='lg:w-3/4'>
                            {q.options.map((option, idx) => (
                                <div key={idx} title={`${option.text}`} className={`pl-5 ${option.isCorrect ? 'text-green-600 font-bold' : ''}`}>
                                    {optionLabels[idx]}. {option.text}
                                    <div className="my-2"><Divider /></div>
                                </div>
                            ))}
                        </div>

                        {q.Image && (
                            <div className='lg:w-1/4 lg:float-right lg:text-right'>
                                <img
                                    src={q.Image}
                                    alt={`${q.question}`}
                                    title={`${q.question}`}
                                    className="mb-4 mx-auto"
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default All;
