import React from 'react';
import CompanyName from './CompanyName'
import { Divider, Link } from '@mui/material';
import Developed from './Developed';


const today = new Date();

const Footer = () => {
    return (
        <div className='mt-8 container mx-auto'>
            <div className='mx-10 CustomFont'>

                <div className='py-5'><Divider /></div>
                <div>&copy; {today.getFullYear()} -  <CompanyName /></div>
                <div><Link href='https://www.driverlicenseprep.com' title=''>driverlicenseprep.com</Link></div>
                <div className='py-4'><Developed /></div>
            </div>
        </div>
    )
}

export default Footer