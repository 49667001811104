import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { pink, teal, red, green } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function Test({ state, language }) {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [answers, setAnswers] = useState({});
    const [showReport, setShowReport] = useState(false);
    const [passThreshold] = useState(0.8); // 80% passing threshold
    const [skippedQuestions, setSkippedQuestions] = useState([]);

    const fadeIn = useSpring({
        opacity: 1,
        transform: 'translateX(0)',
        from: { opacity: 0, transform: 'translateX(100%)' },
        config: { tension: 200, friction: 12 }
    });

    useEffect(() => {
        const fetchQuestions = async () => {
            const fetchUrl = `/States/${state}/${language}.json`;
            console.log('Fetching questions from:', fetchUrl); // Debugging log
            try {
                const response = await fetch(fetchUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Ensure we have at least 50 questions available
                if (data.length < 50) {
                    throw new Error('Not enough questions available.');
                }
                // Randomly select 50 questions without repeats
                const shuffledQuestions = shuffleArray(data).slice(0, 50);
                const questionsWithIds = shuffledQuestions.map((q, index) => ({ id: index + 1, ...q }));
                setQuestions(questionsWithIds);
            } catch (error) {
                console.error("Failed to fetch questions:", error);
            }
        };

        fetchQuestions();
    }, [state, language]);

    // Function to shuffle array elements
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const handleOptionSelect = (optionText) => {
        setSelectedOption(optionText);
        handleSubmitAnswer(optionText);
    };

    const handleSubmitAnswer = (optionText) => {
        const currentQuestion = questions[currentQuestionIndex];
        const isCorrect = currentQuestion.options.find(opt => opt.text === optionText)?.isCorrect || false;

        if (isCorrect) {
            setScore((prev) => prev + 1);
            setCorrectCount((prev) => prev + 1);
        } else {
            setIncorrectCount((prev) => prev + 1);
        }

        setAnswers((prev) => ({
            ...prev,
            [currentQuestionIndex]: isCorrect,
        }));

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex((prev) => prev + 1);
            setSelectedOption(null);
        } else if (skippedQuestions.length > 0) {
            setCurrentQuestionIndex(skippedQuestions[0]);
            setSkippedQuestions(skippedQuestions.slice(1));
        } else {
            setShowReport(true);
        }
    };

    const handleSkipQuestion = () => {
        setSkippedQuestions([...skippedQuestions, currentQuestionIndex]);
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedOption(null);
        } else {
            setShowReport(true);
        }
    };

    const handleReset = () => {
        setCurrentQuestionIndex(0);
        setScore(0);
        setCorrectCount(0);
        setIncorrectCount(0);
        setAnswers({});
        setShowReport(false);
        setSelectedOption(null);
        setSkippedQuestions([]);
    };

    if (questions.length === 0) return <div>Loading...</div>;

    // Calculate pass/fail status

    const passPercentage = score / questions.length;

    let passFailStatus, iconComponent;

    if (passPercentage >= passThreshold) {
        passFailStatus = 'Passed!';
        iconComponent = <CheckCircleIcon style={{ color: green[500] }} />;
    } else {
        passFailStatus = 'Failed, Try again';
        iconComponent = <CancelIcon style={{ color: red[500] }} />;
    }

    const currentQuestion = questions[currentQuestionIndex];

    const optionLabels = ['A', 'B', 'C', 'D'];

    const stateNames = {
        Alabama: 'Alabama',
        NewJersey: 'New Jersey',
        NewYork: 'New York',
        // Add other states as needed
    };

    return (
        <div className="container">
            <h2 className="text-2xl font-bold text-center mt-5">{`${stateNames[state]} Driver's License Practice Test`}</h2>
            {!showReport ? (
                <>
                    <div className='lg:flex lg:gap-5 text-center w-full p-6'>
                        <div className='lg:w-1/3 font-bold text-teal-600'>Correct Answers: <span>{correctCount} / 50</span></div>
                        <div className='lg:w-1/3 font-bold text-pink-600'>Incorrect Answers: <span>{incorrectCount} / 50</span></div>
                        <div className='lg:w-1/3 font-bold text-gray-900'>Score: <span>{score}</span></div>
                    </div>
                    <div className="mt-4 mx-5">
                        <div className="mx-auto">
                            <div className='my-5'><Divider /></div>
                            <animated.div style={fadeIn}>
                                <div className="mb-4">
                                    <div className="text-xl font-bold bg-gray-100 py-3 px-2 border-y border-black">
                                        <span className='pl-5'>{currentQuestion.id}. </span> {currentQuestion.question}
                                    </div>
                                    {currentQuestion.Image && (
                                        <div className='lg:float-right lg:w-1/3'>
                                            <img src={currentQuestion.Image} alt={currentQuestion.question} title={currentQuestion.question} className="my-4 mx-auto" />
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">

                                    <RadioGroup
                                        value={selectedOption}
                                        onChange={(e) => handleOptionSelect(e.target.value)}
                                    >
                                        {currentQuestion.options.map((option, index) => (
                                            <React.Fragment key={index}>
                                                <FormControlLabel
                                                    label={
                                                        <div className="flex items-center">
                                                            <span className="mr-2 bg-green-600 p-2 rounded-md">{optionLabels[index]}</span>
                                                            {option.text}
                                                        </div>
                                                    }
                                                    value={option.text}
                                                    control={<Radio />}

                                                />
                                                {/* Add a Divider after each option except the last one */}
                                                {index < currentQuestion.options.length - 1 && (
                                                    <Divider className="my-2" />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </RadioGroup>
                                </div>
                                <div className="text-center">
                                    <Button
                                        variant="contained"
                                        sx={{ bgcolor: green[500], color: 'white', mr: 2 }}
                                        onClick={() => handleSubmitAnswer(selectedOption)}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ bgcolor: red[500], color: 'white' }}
                                        onClick={handleSkipQuestion}
                                    >
                                        Skip
                                    </Button>
                                </div>
                            </animated.div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='mx-5'>
                    <div className="mt-4">
                        <h2 className={`text-3xl text-center font-bold ${passFailStatus === 'Passed' ? 'text-green-500' : 'text-red-500'}`}>{passFailStatus}</h2>
                        <div className='my-5'><Divider /></div>
                        <div className="text-xl text-center">You answered {correctCount} out of {questions.length} questions correctly.</div>
                        <div className='my-5'><Divider /></div>
                        <div className='mx-auto'>
                            {questions.map((question, index) => (
                                <div
                                    key={index}
                                    className={`my-2 p-4 border ${answers[index] ? 'border-green-500 bg-white' : 'border-red-500 bg-gray-100'} bg-gray-100 rounded`}
                                >
                                    <div className="font-bold text-left">
                                        {question.id}. {question.question}
                                    </div>

                                    {/* Displaying options with their correctness status */}
                                    <div className="mt-2">
                                        {question.options.map((option, optionIndex) => (
                                            <div
                                                key={optionIndex}
                                                className={`flex items-center  ${option.isCorrect ? 'text-green-500' : 'text-red-500'}`}
                                            >
                                                <span className="mr-2">{optionLabels[optionIndex]}.</span>
                                                {option.text}
                                                {option.isCorrect && (
                                                    <span className="ml-2 text-green-500 font-bold">(Correct)</span>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Displaying the overall result for the question */}
                                    <div className="mt-2">
                                        <span className={`font-bold ${answers[index] ? 'text-green-500' : 'text-red-500'}`}>
                                            {answers[index] ? <CheckIcon /> : <ClearIcon />} {answers[index] ? 'Correct' : 'Incorrect'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                sx={{ bgcolor: teal[500], color: 'white' }}
                                onClick={handleReset}
                            >
                                Retake Test
                            </Button>
                        </div>


                    </div>
                </div>
            )}
        </div>
    );
}

export default Test;
