import React from 'react';
// import CountdownTimer from './components/CountdownTimer';
import { Helmet } from 'react-helmet';
// import DriversLicensePractice from './DriversLicensePractice';
import { Button } from '@mui/material';
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DriverLicensePrepImage from './Images/Images/DriverLicensePrep.jpg'

const theme = createTheme({
    palette: {
        primary: {
            main: "#274690", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});


const Home = () => {
    return (
        <div className=''>
            <Helmet>
                <title>Driver License Prep | Practice Permit Tests & Study Guides</title>
                <meta name="description" content="Prepare for your driver's license test with our comprehensive practice permit tests and study guides in English, Arabic, Spanish, and French. Access free permit test prep, DMV prep tests, and more." />
                <meta name="keywords" content="driver license prep, free permit test prep, DMV prep test, driving test preparation, driver license study, drivers license test study guide, permit test preparation, driving test preparation lessons, Arabic, Spanish, French" />
                <meta name="author" content="Driver License Prep" />
                <link rel="canonical" href="https://www.driverlicenseprep.com/" />
                <meta property="og:title" content="Driver License Prep | Practice Permit Tests & Study Guides" />
                <meta property="og:description" content="Prepare for your driver's license test with our comprehensive practice permit tests and study guides in English, Arabic, Spanish, and French. Access free permit test prep, DMV prep tests, and more." />
                <meta property="og:url" content="https://www.driverlicenseprep.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/driverlicenseprep%2FOGHome.jpg?alt=media&token=b4d97375-85c0-4033-a2af-e19985042a18" />
            </Helmet>

            <div className='lg:flex'>

                <div className="lg:w-7/12 p-10">
                    <div className='lg:pt-12'>
                        <h1 className="text-4xl font-bold pb-8 CustomFont">Welcome to Driver License Prep</h1>
                        <div className="text-lg ">
                            Your ultimate resource for studying and practicing for your driver's license exam. We understand that preparing for your driver's license test can be a daunting task, but we're here to make the process as smooth and effective as possible. Our platform offers a comprehensive suite of tools and resources designed to help you pass your test with flying colors, no matter where you are in the United States.
                        </div>
                    </div>


                </div>

                <div className="lg:w-5/12">
                    <div className=''><img src={DriverLicensePrepImage} alt='' title='' className='lg:rounded-bl-3xl lg:text-right mx-auto' /></div>
                </div>
            </div>

            <div className='lg:w-1/2 mx-auto'>
                <div className='mx-8 my-8'>
                    <ThemeProvider theme={theme}>
                        <Button href='/DriversLicensePractice' color='primary' endIcon={<DoubleArrowTwoToneIcon />} fullWidth size='large' className="" variant='contained'>Start Test</Button >
                    </ThemeProvider>
                </div>
            </div>


        </div>
    )
}

export default Home